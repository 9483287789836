$primary-color: #555555;
$primary-color-light: #f1eded;
$primary-color-medium: #bebdbd;
$accent-color: #4c158a;
$accent-color-light: #bfb4d0;
$accent-color-medium: #8e6fb1;

$leftnav-width-expanded: 270px; //width of the leftnav when it is expanded (phone and non-phone)
$leftnav-width-collapsed: 4rem; //width of the leftnav when it is collapsed on a non-phone screen size
$topnav-height: 64px; //height of the topnav
$topnav-background-color: $accent-color; //background color of the topnav
$topnav-font-color: $primary-color-light; //font color of the topnav
$topnav-font-color-hover: $accent-color-light; //font color of the topnav
$menu-background-color-primary: $primary-color-light; //used for dropdown menus and leftnav
$menu-background-color-dropdown: $primary-color-light; //used for dropdown menus and leftnav
$menu-background-color-selected: $accent-color;
$menu-background-color-hover: $primary-color;
$menu-font-color-primary: $primary-color; //font color used for dropdown menus and leftnav
$menu-font-color-selected: #e8eef8; //font color used when a nav item is selected (on that page)
$menu-font-color-disabled: $primary-color-medium; //font color used when a nav item is disabled
$menu-font-color-sub: $primary-color; //font color of submenu items
$menu-background-color-sub-active: rgba(
  255,
  255,
  255,
  0.1
); //background color of the non-active parent and related sub menu items
$menu-font-color-sub-active: $primary-color; //font color of the non-active parent and related sub menu items
$footer-height: 25px; //height of footer
$link-color-primary: $accent-color; //color of links visited and non-visited
$backdrop-background-color: rgba(
  52,
  60,
  73,
  0.65
); //used for dropdown menus and leftnav
$dashboard-header-background-color: $primary-color; //dashboard widget header
$dashboard-header-font-color: #e0e7f0; //dashboard widget header
$dashboard-header-font-color-hover: #8be9db; //dashboard widget header

//breakpoint for phone screen size
@mixin for-phone {
  @media (max-width: 599px) {
    @content;
  }
}

//breakpoint for tablet screen size
@mixin for-tablet {
  @media (min-width: 600px) and (max-width: 1199px) {
    @content;
  }
}

//breakpoint for desktop screen size
@mixin for-desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

//breakpoint for non-desktop screen size
@mixin not-desktop {
  @media (max-width: 1199px) {
    @content;
  }
}

//breakpoint for non-phone screen size
@mixin not-phone {
  @media (min-width: 600px) {
    @content;
  }
}

// Breakpoints

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin mobile-tablet {
  @media (max-width: 767px) {
    @content;
  }
}
