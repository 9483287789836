.success-segment {
  height: 100%;
  .sub {
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 250px;
  }
  @media (max-width: 820px) {
    height: 100vh;
  }
}
