@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.carousel-container {
  .carousel {
    height: 30rem;
    width: 100%;

    .carousel-inner {
      position: relative;
      width: 100%;
      display: block;

      .inner-container {
        text-align: center;
        display: block;
        width: 100%;
        position: relative;

        .carousel-img-container {
          width: 100%;
          height: 30rem;
          display: block;
          position: relative;
          background: $qp-color-secondary;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: contain !important;
            object-position: center !important;
          }
        }
      }
    }
  }
}
