@import "./../../styles/quillpro/_quillpro-variables.scss";

.auto-card {
  &-footer {
    padding: 0px !important;
    text-align: center;
    border-right: 1px solid $qp-color-primary;
    border-left: 1px solid $qp-color-primary;
    border-bottom: 1px solid $qp-color-primary;
    button {
      width: 100%;
    }
  }
  &-title {
    background: $qp-color-primary;
    text-align: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    h4 {
      color: white;
      margin: 5px;
    }
  }
  &-body {
    display: grid;
    padding: 2px !important;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    border-right: 1px solid $qp-color-primary;
    border-left: 1px solid $qp-color-primary;
  }
}

.contact-hover:hover {
  color: purple !important;
}
.contact-hover {
  color: blue !important;
}

.semanticModal-Captcha {
  min-width:420px;
}

.button-row-responsive-container {
  @media only screen and (min-width: 280px) and (max-width: 820px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn-primary {
      width: 100% !important;
      display: block !important;
      .text {
        padding: 0px 15px 0px 20px !important;
      }
    }
  }
}

.entry__space {
  display: flex;
  justify-content: space-between;
  div {
    padding-top: 0.8125rem;
  }
  input {
    width: 6.25rem;
  }
  .loader__container {
    padding-bottom: 10px !important;
  }
}
.modal-body {
  padding-top: 0px;
  padding-bottom: 0px;
}
.modal-footer {
  background: $qp-color-secondary;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.payment {
  &-modal {
    // .active {
    //     padding:0px;
    // }
    .payment-selection {
      display: flex;
      justify-content: space-between;
      .view-btn {
        height: auto;
        line-height: 0.9375rem;
        min-width: 120px;
      }
    }
  }
}

.points-added-buttons {
  color: green !important;
  background: white !important;
}
.points-added-buttons:hover {
  color: white !important;
  background: green !important;
}

.grid {
  &--upcoming {
    display: grid;
    grid-template: 1fr / 1fr;
  }
  // background:red;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  &-basics {
    .title {
      font-weight: bold;
      color: black;
    }
    p {
      display: flex;
      justify-content: space-between;
      padding-right: 10px;
    }
  }
  &-amounts {
    text-align: center;
    border-left-color: $qp-color-primary;
    border-left-width: 3px;
    border-left-style: solid;
    text-decoration: underline;
    p {
      font-weight: bold;
      color: black;
    }
  }
}

.fadeOut {
  opacity: 0;
  display: none;
  width: 0;
  height: 0;
  transition: width 0s 0s, height 0s 0s, opacity 0s;
}
.fadeIn {
  opacity: 1;
  width: 100%;
  height: auto;
  max-height: 500px !important;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
.disabled {
  opacity: 1 !important;
  #amount:disabled {
    background: white !important;
    color: black !important;
  }
}
.ui.celled.grid {
  margin-bottom: 1px;
  margin-top: 1px;
}

.DivTable {
  width: 100%;
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.DivTable > thead,
.DivTable > tbody,
.DivTable > thead > tr,
.DivTable > tbody > tr,
.DivTable > thead > tr > th,
.DivTable > tbody > tr > td {
  display: block;
}

.DivTable > tbody > tr:after,
.DivTable > thead > tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

.DivTable > tbody {
  overflow-y: overlay;
  max-height: 300px;
}

.DivTable > tbody > tr > td,
.DivTable > thead > tr > th {
  width: 16.6666666666666%;
  float: left;
}
.contentPayAction {
  position: relative !important;
  // overflow:hidden !important;
  // z-index:-1000 !important;
  .startTime {
    .rdtPicker {
      position: absolute !important;
      top: calc(100% - 160px) !important;
      right: calc(100% - 156%) !important;
    }
  }
  .endTime {
    .rdtPicker {
      position: absolute !important;
      top: calc(100% - 160px) !important;
      right: calc(100% - 45%) !important;
    }
  }
}

// payment css
.payment-wrap {
  .header-rw {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button.btn-primary {
      border-radius: 3px;
      background-image: linear-gradient(
        102deg,
        #3b1c5a,
        #374db1 163%
      ) !important;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
      padding: 0px 30px !important;
      height: 45px;
      line-height: 45px;
      cursor: pointer;
      .dropdown.icon {
        display: none;
      }
      input {
        cursor: pointer !important;
        height: 100%;
        &.search:focus + .text {
          color: #ffffff !important;
        }
      }
      &:hover,
      &:focus {
        color: #ffffff !important;
      }
      .visible.menu {
        width: 210px;
        padding: 20px 0;
        border-radius: 3px !important;
        box-shadow: 0 11px 14px -10px #e5eced !important;
        border: 0px;
        .item {
          background-repeat: no-repeat;
          background-position: center left 16px;
          padding-left: 45px !important;
          font-size: 13px;
          font-weight: normal;
          color: #5e6267;
          &.account-img {
            background-image: url("/assets/img/icons-bank.svg");
            background-size: 20px;
          }
          &.credit-img {
            background-image: url("/assets/img/icons-payment.svg");
          }
          &.selected {
            background-color: rgba(59, 28, 90, 0.05);
            color: #3b1c5a;
          }
        }
      }
    }
  }
  .header {
    text-align: left !important;
    font-size: 20px !important;
    font-weight: 800;
    margin-bottom: 0px;
    color: #171a1d;
    font-family: "Be Vietnam", sans-serif !important;
  }
  p {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 0px;
  }
  .tabular.menu {
    height: 45px;
    margin-bottom: 15px;
    .item {
      font-family: "Be Vietnam", sans-serif !important;
    }
  }
  .active.tab {
    table {
      border-collapse: separate;
      width: 100%;
      tr {
        td {
          .ui.green {
            background-color: rgba(17, 174, 125, 0.1) !important;
            color: #11ae7d !important;
            box-shadow: unset;
          }
        }
      }
    }
  }
  // table
  .table-wrapper {
    tr {
      th,
      td {
        width: 15%;
        text-align: left;
        &:nth-child(1) {
          width: 25%;
          padding-left: 20px;
        }
        &:last-child {
          width: 15%;
          padding-right: 20px;
        }
      }
    }
    tbody {
      tr {
        height: 102px !important;
        cursor: pointer;
        td {
          font-size: 13px !important;
          font-weight: normal !important;
          color: #5e6267 !important;
          h5 {
            font-size: 14px !important;
            font-weight: 500 !important;
            margin-bottom: 0px;
            color: #171a1d;
          }
        }
        .status-btn {
          width: 80px;
          height: 25px;
          border-radius: 15px;
          background-color: rgba(94, 98, 103, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: bold;
          color: #5e6267;
          text-transform: uppercase;
          &.paid-btn {
            background-color: rgba(17, 174, 125, 0.1);
            color: #11ae7d;
          }
          &.issued-btn {
            background-color: rgba(174, 124, 17, 0.1);
            color: #5e6267;
          }
        }
      }
    }
    &.saved-table-wrap {
      table {
        width: 100%;
        tr {
          height: 80px !important;
          td {
            width: 30%;
            font-weight: 500 !important;
            &:nth-child(1) {
              width: 40% !important;
            }
            &:nth-child(4) {
              width: 10% !important;
              text-align: right;
            }
            img {
              margin-right: 30px;
            }
            .mode-btn {
              width: 120px;
              height: 25px;
              border-radius: 15px;
              background-color: rgba(94, 98, 103, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 11px;
              font-weight: bold;
              text-transform: uppercase;
              &.card-btn {
                background-color: rgba(17, 174, 125, 0.1);
                color: #11ae7d;
              }
            }
          }
          &.card-status-inactive {
            opacity: 0.5;
          }
          &.card-status-tr {
            height: auto !important;
            td {
              padding: 29px 8px 48px 8px;
              &:nth-child(1) {
                padding: 29px 8px 48px 20px;
              }
              &:nth-child(4) {
                padding: 29px 20px 48px 8px;
              }
              &.card-status {
                position: absolute;
                bottom: 14px;
                width: 100% !important;
                left: 0px;
                border-radius: 3px;
                background-color: rgba(255, 152, 33, 0.15);
                height: 30px;
                display: flex;
                align-items: center;
                font-size: 12px !important;
                font-weight: 600 !important;
                padding: 6px 30px;
                color: #e98a1d !important;
              }
            }
          }
        }
      }
    }
    &.autopay-table-wrap {
      table {
        tr {
          td {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100px;
            &:nth-child(1) {
              font-weight: 500 !important;
              color: #171a1d !important;
            }
            &:nth-child(1),
            &:nth-child(2) {
              width: 25%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              width: 10%;
            }
            &.autopay-status-btn {
              width: 80px;
              height: 25px;
              border-radius: 15px;
              background-color: rgba(17, 174, 125, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 11px;
              font-weight: bold;
              color: #11ae7d;
              text-transform: uppercase;
            }
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.delete-data {
  margin-right: 0px;
  cursor: pointer;
}

// payment detail modal
.payment-detail-modal {
  position: fixed;
  top: 30%;
  left: 42%;
  @media only screen and (max-width: 767px) {
    left: 0%;
  }
  z-index: 10;
  .modal-dialog {
    width: 400px;
    @media only screen and (max-width: 767px) {
      width: 300px;
    }
    .modal-content {
      background-color: #f8f8f8;
      box-shadow: unset;
    }
    .modal-header {
      background: unset;
      border: 0px;
      padding: 0px;
      button {
        margin: 0px;
        padding: 15px 20px 0px;
      }
    }
    .modal-body {
      padding: 0px 30px;
      h5 {
        font-size: 18px !important;
        font-weight: 500 !important;
        margin-bottom: 0px;
        color: #171a1d;
      }
      h6 {
        font-size: 14px;
        font-weight: 500 !important;
        color: #5e6267;
        margin-bottom: 37px;
      }
      .price-wrap {
        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          li {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
            label,
            span {
              font-size: 13px;
              font-weight: normal;
              margin-bottom: 0px;
              color: #171a1d;
              font-family: "Be Vietnam", sans-serif !important;
            }
          }
          &.price-charge {
            border-bottom: 1px dashed #cac5c5;
            li {
              &:last-child {
                padding-bottom: 20px;
              }
            }
          }
          &.total-charge {
            border-bottom: 1px dashed #cac5c5;
            li {
              margin-top: 20px;
              &:last-child {
                padding-bottom: 20px;
              }
            }
            .total-price {
              label,
              span {
                font-weight: 600;
                color: #171a1d;
              }
            }
          }
          &.balance-charge {
            li {
              margin-top: 20px;
              label,
              span {
                font-size: 16px;
                font-weight: 600;
                color: #3b1c5a;
              }
            }
          }
        }
      }
    }
    .modal-footer {
      background: unset;
      border: 0px;
      margin: 0px 30px 25px;
      padding: 0px;
      .btn-primary {
        border-radius: 3px;
        background-image: linear-gradient(
          97deg,
          #3b1c5a,
          #374db1 163%
        ) !important;
        width: 100%;
        height: 45px;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        margin: 0px;
        z-index: 9999;
        margin-top: 45px;
      }
    }
  }
}

// payment delete modal
.payment-delete-modal {
  .modal-dialog {
    max-width: 415px;
    .modal-content {
      box-shadow: unset;
      background-color: #f8f8f8;
      border-radius: 3px;
      padding: 40px;
      .modal-body {
        padding: 0px;
        h5 {
          font-size: 20px !important;
          font-weight: bold !important;
          margin-bottom: 9px;
          color: #ef2c2c;
        }
        p {
          font-size: 16px;
          font-weight: normal;
          margin-bottom: 35px;
          color: #5e6267;
          line-height: 28px;
        }
      }
      .modal-footer {
        background: unset;
        border: 0px;
        padding: 0px;
        margin: 0px;
        button {
          height: 45px;
          margin: 0px;
          font-size: 13px;
          font-weight: 800;
          border-radius: 3px;
          color: #ffffff !important;
          text-transform: uppercase;
          &.btn-primary {
            background-color: #ef2c2c !important;
            width: 200px;
            margin-left: 15px;
          }
          &.btn-secondary {
            width: calc(100% - 215px);
            background-color: #6d6d6d !important;
          }
        }
      }
    }
  }
}
.modal-backdrop {
  opacity:0 !important;
  z-index:-1
}
// card payment
.card-payment-wrap {
  .card-payment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .card-payment-subheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px 15px;
    border-radius: 3px;
    background-color: #3b1c5a0d;
    color: #171a1d;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 30px;
    .subheader-value {
      font-weight: bold;
      color: #3b1c5a;
    }
  }
  .back-arrow {
    width: 45px;
    height: 45px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  h5 {
    margin-top: 0px;
    font-size: 20px !important;
    font-weight: 800 !important;
    margin-left: 15px;
    color: #171a1d;
  }
  .card-payment-content {
    .tabular.menu {
      .item {
        width: 50%;
      }
    }
    .active.tab {
      margin-top: 30px !important;
      .list {
        padding: 20px;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        background-color: #ffffff;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        h6 {
          font-size: 14px;
          font-weight: 500 !important;
          color: #171a1d;
          margin-bottom: 0px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          color: #5e6267;
        }
        .select-btn {
          display: flex;
          align-items: center;
          .default-btn {
            border-radius: 15px;
            background-color: rgba(94, 98, 103, 0.1);
            width: 80px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
            margin-right: 30px;
          }
        }
        .bank-name-txt {
          display: flex;
          .card-image {
            min-width: 40px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
        &.selected {
          border: solid 1px #e5eced;
        }
      }
      .tnc-row {
        .tnc-col {
          margin-bottom: 4px;
          label {
            span {
              text-decoration: underline;
              color: #3b1c5a;
            }
          }
        }
      }
    }
    .card-footer-btn {
      display: flex;
      justify-content: flex-end;
      // margin-top: 40px;
      .card {
        width:250px;
        margin-bottom:5px;
        .content{
          padding-left:0px;
          .header{
            padding-bottom:5px;
          }
          .description {
            .row {
              padding-top:0px;
            }
          }
        }
      }
      button {
        width: 250px;
        height: 45px;
        float:right;
        border-radius: 3px;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        margin-top: auto;

        &.btn-add-bank {
          background-color: rgba(59, 28, 90, 0.05) !important;
          color: #3b1c5a !important;
        }
        &.btn-proceed {
          background-image: linear-gradient(
            100deg,
            #3b1c5a,
            #374db1 163%
          ) !important;
          margin-left: 10px;
        }
      }
    }
  }
}
.select-btn {
  input[type="radio"] {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    margin-bottom: 0px;
    cursor: pointer;
    &:checked,
    :not(:checked) {
      position: absolute;
      left: -9999px;
      & + label {
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        margin: 0px;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: -14px;
          width: 17px;
          height: 17px;
          border: 1px solid #3b1c5a;
          border-radius: 50%;
          background: #fff;
        }
        &:after {
          content: "";
          width: 9px;
          height: 9px;
          position: absolute;
          top: -10px;
          left: 4px;
          border-radius: 100%;
          transition: all 0.2s ease;
          background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
        }
      }
    }
    &:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
.empty-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 92px 0px;
  h5 {
    font-size: 20px !important;
    font-weight: bold !important;
    margin-top: 23px;
    margin-bottom: 10px;
    color: #171a1d;
  }
  p {
    font-size: 14px;
    line-height: 26px;
    max-width: 400px;
    margin: 0px auto;
    text-align: center;
  }
}

.payments-table {
  & .t-header {
    position: sticky;
    top: -20px;
    background-color: rgba(248, 248, 248, 1) !important;
    z-index: 10;
  }
}

.data-table {
  &-col {
    &-1 {
      text-align: left;
      margin-left: 40%;
    }
  }
}

.pagination {
  position: absolute;
  bottom: -56px;
  &-index {
    position: absolute;
    bottom: 28px;
    left: 0px;
  }
  &-nav {
    background-image: linear-gradient(276deg, #7127c4, #4c158a);
    border-radius: 3px;
    color: #fff;
    height: 20px;
    font-size: 13px;
    font-weight: 600;
    border: none;
    margin: 2px 6.4px 2px 0;
  }
}

.ellipsis {
  width: 8px;
}

.ellipsis::after {
  position: relative;
  left: -2px;
  bottom: 3px;
  display: inline-block;
  content: "...";
}

.loading {
  width: 8px;
}

.loading::after {
  position: relative;
  left: -2px;
  bottom: 3px;
  display: inline-block;
  animation: dotty steps(1, end) 1s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

.payment-btn {
  width: 160px;
  height: 35px;
  border-radius: 3px;
  background-color: RGB(245, 243, 248) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  color: #3b1c5a !important;
  text-transform: uppercase;
  cursor: pointer;
  &:hover {
    color: white !important;
  }
}

.bookmark-checkbox {
  position: absolute;
  right: 54px;
  top: 0;
  z-index: 1;
}

// Invoice detail style
.invoice-page-wrap{
  .top-header {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    .back-arrow {
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0.05);
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    h5 {
      font-size: 20px !important;
      font-weight: 800 !important;
      margin-top: 0px;
      margin-left: 15px;
      color: #171a1d;
    }
  }
}
.invoice-detail-container{
  margin: 50px 0 0;
  padding: 30px 30px 11px;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  .invoice-card-title{
    font-size: 18px;
    font-weight: 500;
    color: #171a1d;
  }
  .invoice-card-subtitle{
    font-weight: 500;
    color: #5e6267;
  }
  .card-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .card-col{
      font-size: 13px;
      color: #171a1d;
      &.font-colored{
        font-size: 16px;
        font-weight: 600;
        color: #3b1c5a;
      }
    }
  }
  .card-divider{
    width: 100%;
    margin-bottom: 20px;
    border: dashed 1px #e4e2e2;
  }
  .balance-card{
    padding: 25px 20px 25px 30px;
    border-radius: 3px;
    border: solid 1px #eaeaea;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .balance-card-left{
      display: flex;
      align-items: center;
      margin-right: 20px;
      .points-icon{
        margin-right: 14px;
      }
      .balance-title{
        font-size: 15px;
        font-weight: bold;
        color: #3b1c5a;
      }
      .balance-subtitle{
        font-size: 13px;
        font-weight: 500;
        color: #171a1d;
      }
    }
    .balance-card-right{
      .points-input-wrap{
        input{
          width: auto;
          min-width: 200px;
          box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
          background-color: #fff;
          border: 0;
          height: 50px;
          padding: 0 20px;
        }
      }
      .points-applied-wrap{
        border-radius: 3px;
        // box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        padding: 13px 20px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .points-applied-label{
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
          margin-left: 10px;
        }
        .points-applied-edit{
          margin-left: 30px;
          cursor: pointer;
        }
      }
    }
  }
  .services-card-wrap{
    margin: 20px 0 50px;
    padding: 20px 20px 30px 15px;
    border-radius: 3px;
    border: solid 1px #eaeaea;
    background-color: #fff;
    .services-card-title{
      font-size: 15px;
      font-weight: 600;
      color: #171a1d;
      margin-bottom: 20px;
    }
    .services-card-row{
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .services-checkbox{
        padding-top: 6px;
        margin-right: 10px;
      }
      .services-description{
        margin-right: 120px;
      }
      .service-title{
        font-weight: 500;
        color: #171a1d;
      }
      .service-subtitle{
        font-size: 13px;
        color: #5e6267;
        margin-top: 3px;
        .view-link{
          color: #3b1c5a !important;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .service-fee{
        font-size: 14px;
        font-weight: 600;
        color: #3b1c5a;
        margin-left: auto;
      }
    }
  }
}
