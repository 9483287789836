@import "./variables";

.u-text-color-primary {
  color: $primary-color;
}
.ui {
  &.checkbox {
    label {
      font-size: 13px !important;
      &::before {
        width: 14px !important;
        height: 14px !important;
        border-radius: 2px !important;
        border-color: #d4d4d5 !important;
      }
    }
    input:checked {
      ~ label {
        &::before {
          background-color: #3b1c5a !important;
          border-color: #3b1c5a !important;
        }
        &::after {
          content: "" !important;
          color: #fff !important;
          border-left: 1px solid #fff;
          border-bottom: 1px solid #fff;
          width: 8px !important;
          height: 4px !important;
          transform: rotate(-45deg);
          top: 4px !important;
          left: 3px !important;
        }
      }
    }
  }
}
.input-grey {
  background-color: #0000000d !important;
  box-shadow: none !important;
}
