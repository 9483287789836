.star-btn {
  position: absolute;
  height: 35px;
  width: 35px;
  top: 15px;
  right: 15px;
  background: url("/assets/icons/star.svg") no-repeat center white;
  background-size: 20px;
  border-radius: 50%;
}
.points {
  .card {
    .card-title {
      &.title-bottom {
        position: absolute;
        top: -36px;
        right: 0;
        background: #021e40;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: white;
        width: auto;
        padding: 0 20px;
        // border-top-left-radius: 4px;
        // border-bottom-left-radius: 4px;
      }
    }
  }
}

.points .property-details {
  padding-top: 20px;
}

.action-arrow1 {
  height: 50px;
  width: 110px;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url("/assets/icons/right-arrow-stroke.svg") no-repeat
    center#021E40;
  border-bottom-right-radius: 0.4167rem;
  background-size: 20px;
}

.tag {
  color: black;
}

.card img {
  border-radius: 4px 4px 0 0;
}

.past-trips {
  margin-right: 1em;
}

.past-trips-grid {
  width: 100%;
  .card {
    border-radius: 3px;
    box-shadow: 0 11px 14px -10px #e5eced !important;
    background-color: #ffffff;
    padding: 50px 0px;
    .applications-empty__grid {
      display: flex;
      flex-direction: column;
      align-items: center;
      .applications-empty--message {
        background: unset;
        padding: 0px;
        margin-top: 21px;
        h5 {
          font-size: 20px !important;
          font-weight: bold !important;
          margin-bottom: 10px;
          color: #171a1d;
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        max-width: 395px;
        margin: 0px auto 10px;
        text-align: center;
        line-height: 26px;
        &:nth-child(4) {
          max-width: 420px;
          margin-bottom: 25px;
        }
        a {
          color: #3b1c5a;
          text-decoration: underline;
          &:hover {
            color: #3b1c5a;
          }
        }
      }
      .applications-empty--link {
        a {
          border-radius: 3px;
          background-image: linear-gradient(97deg, #3b1c5a, #374db1 163%);
          width: 360px;
          height: 45px;
          color: #fff !important;
          text-align: center;
          font-size: 13px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 26px;
        }
      }
    }
  }
}

// trip css
.renter-trips-wrap {
  .row {
    h2 {
      font-size: 20px;
      font-weight: 800 !important;
      color: #171a1d;
      margin-bottom: 0px;
    }
    h5 {
      font-size: 13px;
      font-weight: normal;
      color: #5e6267;
      margin-top: 0px;
      margin-bottom: 50px;
    }
    h3 {
      font-size: 12px !important;
      font-weight: 600 !important;
      color: #171a1d;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }

  .carousel-full {
    .carousel {
      box-shadow: unset;
      &:hover {
        box-shadow: unset;
      }
      .carousel-item {
        .img-tall-overflow {
          box-shadow: 0 11px 14px -10px #e5eced;
          background-color: #ffffff;
          border-radius: 3px;
          height: auto;
          img {
            height: 150px !important;
            object-fit: cover !important;
          }
        }
      }
      .offers-txt {
        padding: 20px;
        h6 {
          font-size: 14px;
          font-weight: 600 !important;
          margin-bottom: 0px;
          color: #171a1d;
        }
        p {
          font-size: 13px;
          font-weight: normal;
          color: #5e6267;
        }
      }
      .offer-price {
        backdrop-filter: blur(50px);
        background-color: rgba(23, 26, 29, 0.65);
        border-radius: 3px;
        width: 107px;
        height: 30px;
        color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        left: 30px;
        z-index: 9;
        transform: unset;
      }
      .carousel-indicators {
        bottom: -45px;
      }
    }
  }
}
