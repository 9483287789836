/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders

 */
/* QUILLPRO VARIABLES */
/* 
	DO NOT DELETE
	THIS SCSS/CSS FILE IS USED BY SCSS FILES WITHING THE React/view AND React/layout folders

 */
/* QUILLPRO MIXINS */
/* Colors */
@font-face {
  font-family: "Avenir-light", sans-serif;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Book.ttf);
}
@font-face {
  font-family: "Avenir-regular", sans-serif;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Roman.ttf);
}
@font-face {
  font-family: "Avenir-bold", sans-serif;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Medium.ttf);
}
@font-face {
  font-family: "Avenir-black", sans-serif;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/fonts/Avenir-Heavy.ttf);
}

@media only screen and (min-width: 1080px) {
  .payment-wrap-responsive {
    display: flex;
    flex-direction: column;
  }

  .header-rw-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .left-head-responsive {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
  }

  .button-row-responsive-container {
    height: 100px;
    display: flex;
    flex-direction: row;
  }

  .btn.btn-primary {
    border-radius: 3px;
    background-image: linear-gradient(102deg, #3b1c5a, #374db1 163%) !important;
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0px 30px !important;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
  }

  input {
    cursor: pointer !important;
    height: 100%;
    &.search:focus + .text {
      color: #ffffff !important;
    }
  }
  &:hover,
  &:focus {
    color: #ffffff !important;
  }

  .visible.menu {
    width: 210px;
    padding: 20px 0;
    border-radius: 3px !important;
    box-shadow: 0 11px 14px -10px #e5eced !important;
    border: 0px;
  }
}

@media only screen and (min-width: 380px) {
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button.btn-primary {
    border-radius: 3px;
    background-image: linear-gradient(102deg, #3b1c5a, #374db1 163%) !important;
    font-size: 13px;
    font-weight: 800;
    text-transform: uppercase;
    padding: 0px 30px !important;
    height: 45px;
    line-height: 45px;
    cursor: pointer;
    margin: 10px;
    width: 230px;
  }
}
