@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";

.applications-empty {
  &__container {
    display: block;

    .card {
      overflow: hidden !important;
    }

    .card-body {
      padding: 0;
    }
  }

  &__grid {
    display: grid;
    grid-template: auto / fit-content(100%) 5fr;
    // grid-template: auto / 1fr 5fr;
  }

  &--message {
    grid-column: 1 / -1;
    // border-bottom: 2px solid $qp-color-primary;
    background: linear-gradient(to right, $qp-color-secondary, $qp-color-white);
    padding: 1rem 0 0 1rem;
  }
  &--icon {
    color: $qp-color-primary;
    font-size: 2rem;
    background: $qp-color-secondary;
    padding: 1rem;
  }
  &--link {
    background: linear-gradient(to right, $qp-color-secondary, $qp-color-white);
    display: flex;
    align-items: center;
    a {
      // border:2px solid $qp-color-primary;
      padding: 8px;
      border-radius: 5px;
      // text-align:center;
      // margin: 0 auto;
    }
    a:hover {
      align-self: center;
      color: $qp-color-white !important;
      background: $qp-color-primary;
    }
  }
}
