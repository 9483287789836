.flash-container {
  #flashmessage {
    position: fixed;
    left: 0;
    top: 10px;
    text-align: center;
    z-index: 15000;
    width: 100%;
  }
}
