@import "../../../SiteShared.scss";

$width: 250px;

.actions {
  position: relative;

  .dots {
  }

  .actions-wrapper {
    position: absolute;
    top: 25px;
    left: -50%;
    border-radius: 3px;
    background-color: #fff;
    padding: 20px 25px;
    min-width: $width;
    box-shadow: 0 11px 14px -10px #e5eced;
  }
}

@include for-phone {
  .actions {
    .actions-wrapper {
      left: calc((100vw - $width) * -1);
    }
  }
}
