.lea-loader {
  &.in-table {
    margin-left: 33vw;
    padding-bottom: 20px;
  }
  &.stepper {
    transform: scale(0.3, 0.3);
  }
  &.center-loader {
    margin-left: 33vw;
  }
  height: 150px;
  width: 150px;
  &-text {
    display: block;
    text-align: center;
    color: #000000de;
    font-size: 24px;
    font-family: "Lato, Helvetica Neue";
    word-wrap: none;
  }
  &-logo {
    animation: rotate 1250ms ease-in-out infinite;
    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(720deg);
      }
    }
  }
}
